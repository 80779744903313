import './App.css';
import { Route, Routes, BrowserRouter, useLocation } from "react-router-dom";
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import ProductsOverview from './routes/products.route';
import ProductCategoryRoute from "./routes/product-category.route";
import Technologies from './routes/technologies.route';
import Locations from './routes/locations.route.jsx';
import Sustainability from './routes/sustainability.route';
import Landingpage from './routes/landingpage.route';
import AboutUs from './routes/about-us.route';
import OSRNavbar from './components/navbar/navbar.component';
import BrandingLogo from './misc/BrandingLogo';
//import NavbarBase from './components/navbar/navbar-base.component';
import BackgroundContainer from './components/container/BackgroundContainer';
import CacheTest from './routes/cache-test.route';
import ResponsiveTest from './routes/responsivetest.route';

import mobile_landingpageBackground from './assets/webm/stills/Totale-Still.jpg';
import mobile_productsBackground from './assets/webm/stills/Produktion-Still.jpg';

import mobile_technologyBackground from './assets/webm/stills/Technologie-Still.jpg';
import mobile_technologyASEBackground from './assets/webm/stills/technology/Technology_ASE_NEW.jpg';
import mobile_technologyEngineeringBackground from './assets/webm/stills/technology/Technology_Engineering_2a.jpg';
import mobile_technologyAssemblyBackground from './assets/webm/stills/technology/Technology_Machining_a_Assembling_03c_V2.jpg';
import mobile_technologyGDCBackground from './assets/webm/stills/technology/Technology_Gravity-Die-Casting_03e.jpg';
import mobile_technologyHPDCBackground from './assets/webm/stills/technology/Technology_Die-Casting_07b.jpg';

import mobile_productsEnginesBackground from './assets/webm/stills/products/Products_Motor_u_Antriebskomponenten_01f_Scene.jpg';
import mobile_productsTransmissionsBackground from './assets/webm/stills/products/Products_Getriebebauteile_03b_Scene.jpg';
import mobile_productsElectricBackground from './assets/webm/stills/products/Products_E-Mobility_01e_Scene.jpg';
import mobile_productsChassisBackground from './assets/webm/stills/products/Products_Karosserie_Fahrw_Lenk_01d_Scene.jpg';

import mobile_sustainabilityBackground from './assets/webm/stills/sustainability/Technology_Sustainabilty_10a_V3_Scene_Finish_01c_bg.jpg';

import mobile_locationsBackground from './assets/webm/stills/locations/Weltkugel.jpg';
import mobile_locationsBackgroundReponsive from './assets/webm/stills/locations/Standorte_final.jpg';
import BackdropModalImprint from './components/container/BackdropModalImprint';
import BackdropModalPrivacy from './components/container/BackdropModalPrivacy';
import { Navigate } from 'react-router-dom';
import LoadingScreen from "./components/container/LoadingScreen";
import GlobalLoadingScreenContext from "./contexts/globalLoadingScreenContext";
import AppStateContext from "./contexts/appStateContext";
import TechnologySystemsEngineeringAssemblyRoom from "./routes/technology-systems-engineering-assembly-room.route";
import TechnologySystemsEngineeringBatteryTestingRoom from "./routes/technology-systems-engingeering-batterytesting-room.route.jsx";
import TechnologyLightMetalCastingAssemblyRoomRoute from "./routes/technology-light-metal-casting-assembly-room.route";
import TechnologyLightMetalCastingGravityDieCastingRoomRoute
  from "./routes/technology-light-metal-casting-gravity-die-casting-room.route";
import TechnologyEDEcoDesignRoom from './routes/technology-ed-eco-design-room.route.jsx';
import TechnologyEDRapidPrototypingRoom from './routes/technology-ed-rapidprototyping.route.jsx';
import TechnologyEDCastingAlloysRoom from './routes/technology-ed-castingalloys.route.jsx';
import TechnologyEDSimulationsRoom from './routes/technology-ed-simulations.route.jsx';
import TechnologyEDWeightOptimRoom from './routes/technology-ed-weightopti.route.jsx';

import TechnologyLightMetalCastingHighPressureDieCastingRoomRoute
  from "./routes/technology-light-metal-casting-high-pressure-die-casting-room.route";
import TechnologyLightMetalCastingMegaCastingRoom from "./routes/technology-light-metal-casting-mega-casting-room.route";
import Footer from './misc/Footer.jsx';

const App = (props) => {
  const [loadingScreenVisible, setLoadingScreenVisible] = useState(false);

  const [backgroundImage, setBackgroundImage] = useState("empty_page.png");
  const [backgroundImageStyleOverride, setBackgroundImageStyleOverride] = useState(null);
  const [layeredMovies, setLayeredMovies] = useState([]);

  const [backgroundImageReady, setBackgroundImageReady] = useState(false);
  const [layeredMoviesReady, setLayeredMoviesReady] = useState(false);

  const backgroundImageOrLayeredMovieReady = useMemo(() => {
    return backgroundImageReady || layeredMoviesReady;
  }, [backgroundImageReady, layeredMoviesReady]);


  const changeLayeredMovies = useCallback((...params) => {
    setLayeredMoviesReady(false);
    setLayeredMovies(...params);
  }, [setLayeredMovies, setLayeredMoviesReady]);

  const changeBackgroundImage = useCallback((...params) => {
    setBackgroundImageReady(false);
    setBackgroundImage(...params);
  }, [setBackgroundImage, setBackgroundImageReady]);

  const changeBackgroundImageStyleOverride = useCallback((style) => {
    setBackgroundImageStyleOverride(style);
  }, [setBackgroundImageStyleOverride]);

  const [mobileBackButtonVisible, setMobileBackButtonVisible] = useState(false);
  const [mobileBackButtonLocation, setMobileBackButtonLocation] = useState(null);

  const onLayeredMovieReady = useCallback(() => {
    setLayeredMoviesReady(true);
  }, [setLayeredMoviesReady]);

  const onBackgroundImageReady = useCallback(() => {
    setBackgroundImageReady(true);
  }, [setBackgroundImageReady]);


  /*React.useEffect(() => {
    var _mtm = window._mtm = window._mtm || [];
    _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src='https://analytics.creapolis.de/js/container_an00vKXk.js'; s.parentNode.insertBefore(g,s);
   }, [])*/

  const ucUIEventCallback = (event) => {
    //const allButtons = window.document.getElementsByTagName("button");
    //console.log("all buttons:", allButtons)
    const eventType = event.detail.type;
    //console.log("New constent type:", eventType);
    try {
      const consentServices = window.UC_UI?.getServicesBaseInfo();

      if (consentServices) {
        for (let index = 0; index < consentServices.length; index++) {
          const service = consentServices[index];
          if (service.id === "u6fxocwTs" || service.id === "vtoY2LcrVB4gHm") {
            if (service.consent.status === false) {
              // stop tracking now
              // nothing needs to be done right now.

            }
          }

        }
      }
    } catch (e) { }
    if (eventType === "DENY_ALL" || eventType === "SAVE") {
      setTimeout(() => {
        window.location.reload();
      }, 250)

    }
  }

  window.addEventListener('CookiebotOnConsentReady', () => {
    document.location.reload()
  });
  window.addEventListener("UC_UI_CMP_EVENT", ucUIEventCallback);

  useEffect(() => {


    /*
        setInterval(() => {
          const allButtons = window.document.getElementsByTagName("button");
          console.log("all buttons:", allButtons)
          for (let index = 0; index < allButtons.length; index++) {
            const button = allButtons[index];
            console.log(button.getAttribute("data-testid"))
    
          }
        }, 3500);
    */

  }, [])

  return (
    <div className="App">

      <div className="AppBody">
        <AppStateContext.Provider value={{ mobileBackButtonVisible, setMobileBackButtonVisible, mobileBackButtonLocation, setMobileBackButtonLocation }}>
          <GlobalLoadingScreenContext.Provider value={{ loadingScreenVisible, setLoadingScreenVisible, mobileBackButtonLocation, setMobileBackButtonLocation }}>
            {/*<BrowserRouter basename={'/' + xlang} key={xlang}>*/}
            <BrowserRouter>
              <BackdropModalImprint />
              <BackdropModalPrivacy />
              <OSRNavbar />
              <BrandingLogo />
              <Footer />
              {loadingScreenVisible && <LoadingScreen />}
              {/*<NavbarBase></NavbarBase>*/}
              <div id="background-content-container">
                {/*<BackgroundMovie video={backgroundMovie}></BackgroundMovie>*/}
                {<BackgroundContainer onLayeredMovieReady={onLayeredMovieReady} onBaseImageLoaded={onBackgroundImageReady} baseImage={backgroundImage} baseImageStyleOverride={backgroundImageStyleOverride} layeredMovies={layeredMovies} />}
              </div>
              <Routes>
                <Route path={"/"} element={<Landingpage isBackgroundReady={backgroundImageOrLayeredMovieReady} changeLayeredMovies={changeLayeredMovies} changeBackgroundImage={changeBackgroundImage} />} />
                <Route exact path="products" element={<ProductsOverview isBackgroundReady={backgroundImageOrLayeredMovieReady} changeLayeredMovies={changeLayeredMovies} changeBackgroundImage={changeBackgroundImage} />} />
                <Route exact path="products/electric-mobility" element={<ProductCategoryRoute route="products/electric-mobility" productCategory="electric-mobility" productCategoryLabel="ELECTRIC MOBILITY" changeLayeredMovies={changeLayeredMovies} changeBackgroundImage={changeBackgroundImage} />} />
                <Route exact path="products/engines" element={<ProductCategoryRoute route="products/engines" productCategory="engines" productCategoryLabel="ENGINE AND DRIVETRAIN" changeLayeredMovies={changeLayeredMovies} changeBackgroundImage={changeBackgroundImage} />} />
                <Route exact path="products/transmissions" element={<ProductCategoryRoute route="products/transmissions" productCategory="transmissions" productCategoryLabel="TRANSMISSION" changeLayeredMovies={changeLayeredMovies} changeBackgroundImage={changeBackgroundImage} />} />
                <Route exact path="products/body-chassis-steering" element={<ProductCategoryRoute route="products/body-chassis-steering" productCategory="body-chassis-steering" productCategoryLabel="BODY - CHASSIS - STEERING" changeLayeredMovies={changeLayeredMovies} changeBackgroundImage={changeBackgroundImage} />} />
                <Route exact path="competences" element={<Technologies isBackgroundReady={backgroundImageOrLayeredMovieReady} changeLayeredMovies={changeLayeredMovies} changeBackgroundImage={changeBackgroundImage} />} />

                <Route exact path="competences/eco-design" element={<TechnologyEDEcoDesignRoom changeLayeredMovies={changeLayeredMovies} changeBackgroundImage={changeBackgroundImage} />} />
                <Route exact path="competences/simulations" element={<TechnologyEDSimulationsRoom changeLayeredMovies={changeLayeredMovies} changeBackgroundImage={changeBackgroundImage} />} />
                <Route exact path="competences/rapid-prototyping" element={<TechnologyEDRapidPrototypingRoom changeLayeredMovies={changeLayeredMovies} changeBackgroundImage={changeBackgroundImage} />} />
                <Route exact path="competences/optimisation" element={<TechnologyEDWeightOptimRoom changeLayeredMovies={changeLayeredMovies} changeBackgroundImage={changeBackgroundImage} />} />
                <Route exact path="competences/casting-alloys" element={<TechnologyEDCastingAlloysRoom changeLayeredMovies={changeLayeredMovies} changeBackgroundImage={changeBackgroundImage} />} />

                <Route exact path="competences/mega-casting" element={<TechnologyLightMetalCastingMegaCastingRoom changeLayeredMovies={changeLayeredMovies} changeBackgroundImage={changeBackgroundImage} />} />
                <Route exact path="competences/high-pressure-die-casting" element={<TechnologyLightMetalCastingHighPressureDieCastingRoomRoute changeLayeredMovies={changeLayeredMovies} changeBackgroundImage={changeBackgroundImage} />} />
                <Route exact path="competences/gravity-die-casting" element={<TechnologyLightMetalCastingGravityDieCastingRoomRoute changeLayeredMovies={changeLayeredMovies} changeBackgroundImage={changeBackgroundImage} />} />
                <Route exact path="competences/machining-and-assembling" element={<TechnologyLightMetalCastingAssemblyRoomRoute changeLayeredMovies={changeLayeredMovies} changeBackgroundImage={changeBackgroundImage} />} />
                {/*   *** Raum aktuell inaktiv, es fehlt noch content *** */}
                {/*<Route exact path="competences/engineering" element={<TechnologyEngineering changeMobileBackgroundImage={changeMobileBackgroundImage} changeLayeredMovies={changeLayeredMovies} changeBackgroundImage={changeBackgroundImage} />} /> */}

                <Route exact path="competences/battery-assembly-systems-engineering" element={<TechnologySystemsEngineeringAssemblyRoom changeLayeredMovies={changeLayeredMovies} changeBackgroundImage={changeBackgroundImage} changeBackgroundImageStyleOverride={changeBackgroundImageStyleOverride} />} />
                <Route exact path="competences/battery-systems" element={<TechnologySystemsEngineeringBatteryTestingRoom changeLayeredMovies={changeLayeredMovies} changeBackgroundImage={changeBackgroundImage} changeBackgroundImageStyleOverride={changeBackgroundImageStyleOverride} />} />
                <Route exact path="locations" element={<Locations changeLayeredMovies={changeLayeredMovies} changeBackgroundImage={changeBackgroundImage} />} />
                <Route exact path="sustainability" element={<Sustainability changeLayeredMovies={changeLayeredMovies} changeBackgroundImage={changeBackgroundImage} />} />
                {/*<Route exact path="aboutus" element={<AboutUs></AboutUs>} />*/}
                {/*Redirect all 404's to home*/}
                <Route path="/:noRouteFound" element={<Navigate to="/" />} />
              </Routes>
            </BrowserRouter>
          </GlobalLoadingScreenContext.Provider>
        </AppStateContext.Provider>
      </div>
    </div>
  );
}

export default App;
