import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FaBars, FaLinkedin, FaYoutube } from 'react-icons/fa';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import SearchBar from './searchbar/searchbar.component';
import './navbar.css';
import { useMediaQuery } from 'react-responsive'
import MobileNavbar from './mobile-navbar.component';

function ToggleImprintModal() {
  //console.log("Showing Impressum modal(add)");
  const _modal = document.getElementById('backdrop-modal-imprint');
  if (_modal) {
    // deactivate modal
    _modal.classList.toggle('active-modal');
  }
  try { window._paq && window._paq.push(['trackEvent', "ImprintModal", 'ModalToggled', 'Imprint']); } catch (e) { }
}
function TogglePrivacyModal() {
  //console.log("Showing privacy modal(toggle)");
  const _modal = document.getElementById('backdrop-modal-privacy');
  if (_modal) {
    // deactivate modal
    _modal.classList.toggle('active-modal');
  }
  try { window._paq && window._paq.push(['trackEvent', "PrivacyModal", 'ModalToggled', 'Privacy']); } catch (e) { }
}

function OSRNavbar() {
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1450px)' });
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 915px)' });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });

  let BASE_URL = "https://dev-handtmann-showroom.creapolis.de/"; //TODO: change https and live domain
  //BASE_URL = "http://10.0.0.168:3000/";

  const burgerMenuStyle = {
    marginLeft: '0%',
  };

  const headingStyle = {
    marginLeft: '10%',
  };

  const navLogoStyle = {
    position: 'absolute',
    right: '0%',
    top: '10%',
    backdropFilter: 'blur(4px)',
  };

  const searchBarStyle = {
    marginLeft: '10%',
    width: '525px',
    borderWidth: '0px',
    borderColor: 'white',
  };

  const socialIconsStyle = {
    fontSize: '16px',
  };

  const iconOffsetStyle = {
    marginBottom: '4px',
  }
  const iconRightOffsetStyle = {
    marginTop: '5px',
    float: 'right',
  }

  const bgStyle = {
    fontWeight: 'bold',
    fontSize: '16px',
  };

  //const [backgroundColor, setBackgroundColor] = React.useState();

  const navDropdownTitle = (<React.Fragment><FaBars /></React.Fragment>);
  if (isTabletOrMobile) {
    return (
      <MobileNavbar />
    );
  }
  return (
    <Navbar className="navbar-custom" bg="light" expand="sm" fixed='top' >
      <Container style={burgerMenuStyle}>
        {/*<Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">*/}
        <Nav className="me-auto">
          <NavDropdown title={navDropdownTitle} id="basic-nav-dropdown" onClick={() => { try { window._paq.push(['trackEvent', "BurgerMenu", 'MenuToggled']); } catch (e) { } }}>
            <NavDropdown.Header style={bgStyle}>Products:</NavDropdown.Header>
            <NavDropdown.Item href={BASE_URL + "products/engines?tx=0"}>Engine and Drivetrain</NavDropdown.Item>
            <NavDropdown.Item href={BASE_URL + "products/transmissions?tx=0"}>Transmission</NavDropdown.Item>
            <NavDropdown.Item href={BASE_URL + "products/body-chassis-steering?tx=0"}>Body - Chassis - Steering</NavDropdown.Item>
            <NavDropdown.Item href={BASE_URL + "products/electric-mobility?tx=0"}>Electric Mobility</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Header style={bgStyle} href={BASE_URL + "competences?tx=0"}>Competences:</NavDropdown.Header>
            <NavDropdown.Item href={BASE_URL + "competences/battery-assembly-systems-engineering?tx=0"}>Systems-Engineering</NavDropdown.Item>
            <NavDropdown.Item href={BASE_URL + "competences/high-pressure-die-casting?tx=0"}>High-Pressure Die Casting</NavDropdown.Item>
            <NavDropdown.Item href={BASE_URL + "competences/gravity-die-casting?tx=0"}>Gravity Die Casting</NavDropdown.Item>
            <NavDropdown.Item href={BASE_URL + "competences/machining-and-assembling?tx=0"}>Assembly</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href={BASE_URL + "sustainability"}>Sustainability</NavDropdown.Item>
            <NavDropdown.Item href={BASE_URL + "locations"}>Locations</NavDropdown.Item>
            <NavDropdown.Item target="_blank" href={"https://www.handtmann.de/en/light-metal-casting"}>About us</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item target="_blank" href="https://www.handtmann.de/en/light-metal-casting">
              Homepage
            </NavDropdown.Item>
            <span onClick={() => TogglePrivacyModal()}>
              <NavDropdown.Item target="_blank" href="#">
                Privacy
              </NavDropdown.Item>
            </span>
            <span onClick={() => ToggleImprintModal()}>
              <NavDropdown.Item target="_blank" href="#">
                Imprint
              </NavDropdown.Item>
            </span>
            <NavDropdown.Item style={socialIconsStyle} target="_blank" href="https://www.linkedin.com/company/albert-handtmann-metallgusswerk-gmbh-&-co-kg/mycompany/?viewAsMember=true"><FaLinkedin style={iconOffsetStyle}></FaLinkedin> <span style={{ fontSize: '16px' }}>LinkedIn</span> <BsBoxArrowUpRight style={iconRightOffsetStyle}></BsBoxArrowUpRight></NavDropdown.Item>
            <NavDropdown.Item style={socialIconsStyle} target="_blank" href="https://www.youtube.com/user/HandtmannMetallguss"><FaYoutube style={iconOffsetStyle}></FaYoutube> <span style={{ fontSize: '16px' }}>YouTube</span> <BsBoxArrowUpRight style={iconRightOffsetStyle}></BsBoxArrowUpRight></NavDropdown.Item>

          </NavDropdown>
          <Navbar.Brand style={headingStyle} href="/"> Handtmann</Navbar.Brand>
          <Navbar.Brand style={searchBarStyle}>
            {<SearchBar />}
          </Navbar.Brand>

          {/*<Navbar.Brand style={navLogoStyle} href="/"><img src={require('../../assets/Logo.png')}></img></Navbar.Brand>*/}

          {/*<input  style={searchBarStyle}
            type="text"
            value={"What are you looking for today?"}
            onFocus={() => setBackgroundColor('orange')}
  />*/}
        </Nav>
        {/*</Navbar.Collapse>*/}
      </Container>
    </Navbar>
  );
}

export default OSRNavbar;
